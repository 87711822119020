<!-- 출석부 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">출석부</h3>
                </div>
              </div>
            </div>
            <div class="card">
              <!-- 졸업식 2일 전 ~ 후 7일 기간 졸업장 & 상장 다운로드 버튼 활성화 -->
              <div class="row ml-3" v-if="isGraduationPeriod">
                <div class="mt-3 ml-3" v-if="diploma">
                  <base-button
                    outline
                    type="warning"
                    icon
                    @click="goDiploma(memberNo)"
                  >
                    <span class="btn-inner--text">졸업장 내려받기</span>
                  </base-button>
                </div>
                <div class="mt-3 ml-3" v-if="award">
                  <base-button
                    outline
                    type="warning"
                    icon
                    @click="goAward(memberNo)"
                  >
                    <span class="btn-inner--text">상장 내려받기</span>
                  </base-button>
                </div>
              </div>

              <div>
                <div class="card-header border-0">
                  <br />
                  <h2><font color="blue">[졸업요건]</font></h2>
                  <p>
                    1. 전체 수업 70%( {{ totalNumber }}회 중
                    {{ Math.ceil(totalNumber * 0.7) }}회) 이상 출석
                  </p>
                  <p>2. 과목별 50%이상 출석</p>
                  <p>
                    3. 수업시작 후 15분이내 지각, 15분 초과 지각 시 결석 처리
                  </p>
                  <p>
                    4. 수업종료 전 15분이내 조퇴, 15분 초과 조퇴 시 결석 처리
                  </p>
                  <p>
                    5. 지각과 조퇴의 횟수를 합하여 3회 = 결석 1회<br />
                    &nbsp;&nbsp;&nbsp;(졸업사정 시 반영됨 - 학사과정 중 미반영)
                  </p>
                  <p>
                    - {{ section2Name }} {{ section2Number }}회 중
                    {{ Math.ceil(section2Number * 0.5) }}회 이상<br />
                    - {{ section3Name }} {{ section3Number }}회 중
                    {{ Math.ceil(section3Number * 0.5) }}회 이상<br />
                    - {{ section4Name }} {{ section4Number }}회 중
                    {{ Math.ceil(section4Number * 0.5) }}회 이상<br />
                    - 즉문즉설 {{ talkNumber }}회 중
                    {{ Math.ceil(talkNumber * 0.5) }}회 이상<br />
                    - 오프라인 실천활동 {{ offlineNumber }}회 중
                    {{ Math.ceil(offlineNumber * 0.5) }}회 이상
                  </p>
                </div>

                <div class="mx-4">
                  <table align="center" width="100%" :Data="tableData">
                    <td class="list-group-item px-2">
                      <H3>온라인 생방송 수업(전체)</H3>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ totalNumber }}회중</div>
                        <div class="child">출석: {{ sum1 }}</div>
                        <div class="child">지각,조퇴: {{ sum2 }}</div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H3>{{ section1Name }}</H3>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section1Number }}회중</div>
                        <div class="child">
                          출석: {{ onlineSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ onlineSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H3>{{ section2Name }}</H3>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업{{ section2Number }}회중</div>
                        <div class="child">
                          출석: {{ onlineSection2Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ onlineSection2Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H3>{{ section3Name }}</H3>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section3Number }}회중</div>
                        <div class="child">
                          출석: {{ onlineSection3Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ onlineSection3Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H3>{{ section4Name }}</H3>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section4Number }}회중</div>
                        <div class="child">
                          출석: {{ onlineSection4Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ onlineSection4Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H3>즉문즉설</H3>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ talkNumber }}회중</div>
                        <div class="child">출석: {{ talkSection1Attend }}</div>
                        <div class="child">
                          지각,조퇴: {{ talkSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H3>실천활동</H3>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ offlineNumber }}회중</div>
                        <div class="child">
                          출석: {{ offlineSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ offlineSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import { ref } from "vue";
import moment from "moment-timezone";
import {
  BSECTION,
  KSECTION,
  BLESSON_NUMBER,
  KLESSON_NUMBER,
} from "@/consts/class";
export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
  },
  // 데이터 객체 추가
  setup() {
    const perPage = ref(2); // 페이지당 레코드 수
    const question2 = ref("");
    const tableData = ref([]);
    const tableToggle = ref(true);
    const show = ref(false);
    const onlineSection1Attend = ref(0);
    const onlineSection1Late = ref(0);
    const onlineSection2Attend = ref(0);
    const onlineSection2Late = ref(0);
    const onlineSection3Attend = ref(0);
    const onlineSection3Late = ref(0);
    const onlineSection4Attend = ref(0);
    const onlineSection4Late = ref(0);
    const offlineSection1Attend = ref(0);
    const offlineSection1Late = ref(0);
    const talkSection1Attend = ref(0);
    const talkSection1Late = ref(0);
    const onlineLessonAttend = ref(0);
    const onlineMeetAttend = ref(0);
    const sum1 = ref(0);
    const sum2 = ref(0);
    const sum0 = ref(0);
    const diploma = ref(false);
    const award = ref(false);
    const memberNo = ref(0);
    const buldae = ref(false);
    const kyoungdae = ref(false);
    const isGraduationPeriod = ref(false);
    let section1Name = ref("");
    let section2Name = ref("");
    let section3Name = ref("");
    let section4Name = ref("");
    let section1Number = ref(0);
    let section2Number = ref(0);
    let section3Number = ref(0);
    let section4Number = ref(0);
    let totalNumber = ref(0);
    let talkNumber = ref(0);
    let offlineNumber = ref(0);
    let userInfo = ref(null);
    const token = api.Auth.tokenContent;

    // api 호출
    const getDataFromApi = async () => {
      try {
        const responseUser = await api.get(`service/user/user`);

        if (!api.isSuccess(responseUser)) {
          return;
        }
        const result = responseUser.data.data;
        userInfo.value = responseUser.data.data;
        if (
          result.role.includes("master") ||
          result.role.includes("manager") ||
          (result.diploma && result.diploma !== "")
        ) {
          diploma.value = true;
        }
        if (
          result.role.includes("master") ||
          result.role.includes("manager") ||
          (result.attendType && result.attendType !== "")
        ) {
          award.value = true;
        }
        memberNo.value = result.memberNo;
        if (result.grade === "불교대학") {
          buldae.value = true;
          kyoungdae.value = false;
        } else if (result.grade === "경전대학") {
          kyoungdae.value = true;
          buldae.value = false;
        }
        if (result.grade == "불교대학") {
          talkNumber.value = BLESSON_NUMBER.talk;
          offlineNumber.value = BLESSON_NUMBER.offline;
        } else if (result.grade == "경전대학") {
          talkNumber.value = KLESSON_NUMBER.talk;
          offlineNumber.value = KLESSON_NUMBER.offline;
        }

        if (memberNo.value) {
          // 하드 코딩.
          const url2 = `service/class2/lesson3?bound=100&grade=${result.grade}&year=${result.year}&semester=${result.semester}`;
          const responseLessons = await api.get(url2);
          if (!api.isSuccess(responseLessons)) {
            return;
          }
          const data2 = responseLessons.data;
          const d2 = data2.data;
          const section1 = [];
          const section2 = [];
          const section3 = [];
          const section4 = [];
          let section = [];
          if (result.grade == "불교대학") {
            section = BSECTION;
            section1Name.value = BSECTION[1];
            section2Name.value = BSECTION[2];
            section3Name.value = BSECTION[3];
            section4Name.value = BSECTION[4];
          } else {
            section = KSECTION;
            section1Name.value = KSECTION[1];
            section2Name.value = KSECTION[2];
            section3Name.value = KSECTION[3];
            section4Name.value = KSECTION[4];
          }

          if (data2.rows > 0) {
            for (const row of d2) {
              if (row.section == section[1]) {
                section1.push(row.lessonNo);
              } else if (row.section == section[2]) {
                section2.push(row.lessonNo);
              } else if (row.section == section[3]) {
                section3.push(row.lessonNo);
              } else if (row.section == section[4]) {
                section4.push(row.lessonNo);
              }
            }
          }
          section1Number.value = section1.length;
          section2Number.value = section2.length;
          section3Number.value = section3.length;
          section4Number.value = section4.length;

          totalNumber.value =
            section1Number.value +
            section2Number.value +
            section3Number.value +
            section4Number.value +
            talkNumber.value +
            offlineNumber.value;

          const url = `service/class2/attend/${memberNo.value}`;
          const responseAttend = await api.get(url);
          if (!api.isSuccess(responseAttend)) {
            return;
          }
          const data = responseAttend.data;
          const d = data.data;
          if (data.msg === "ok") {
            d.onlineSection1Attend = 0;
            d.onlineSection1Late = 0;
            d.onlineSection2Attend = 0;
            d.onlineSection2Late = 0;
            d.onlineSection3Attend = 0;
            d.onlineSection3Late = 0;
            d.onlineSection4Attend = 0;
            d.onlineSection4Late = 0;
            d.offlineSection1Attend = 0;
            d.offlineSection1Late = 0;
            d.talkSection1Attend = 0;
            d.talkSection1Late = 0;

            for (let i = 0; i < section1.length; i++) {
              if (eval("d.onlineLesson" + section1[i] + ".attend == 1")) {
                d.onlineSection1Attend++;
              } else if (
                eval("d.onlineLesson" + section1[i] + ".attend == -1")
              ) {
                d.onlineSection1Attend++;
                d.onlineSection1Late++;
              } else if (
                eval("d.onlineLesson" + section1[i] + ".attend == -2")
              ) {
                d.onlineSection1Attend++;
                d.onlineSection1Late++;
              }
            }
            for (let i = 0; i < section2.length; i++) {
              if (eval("d.onlineLesson" + section2[i] + ".attend == 1")) {
                d.onlineSection2Attend++;
              } else if (
                eval("d.onlineLesson" + section2[i] + ".attend == -1")
              ) {
                d.onlineSection2Attend++;
                d.onlineSection2Late++;
              } else if (
                eval("d.onlineLesson" + section2[i] + ".attend == -2")
              ) {
                d.onlineSection2Attend++;
                d.onlineSection2Late++;
              }
            }
            for (let i = 0; i < section3.length; i++) {
              if (eval("d.onlineLesson" + section3[i] + ".attend == 1")) {
                d.onlineSection3Attend++;
              } else if (
                eval("d.onlineLesson" + section3[i] + ".attend == -1")
              ) {
                d.onlineSection3Attend++;
                d.onlineSection3Late++;
              } else if (
                eval("d.onlineLesson" + section3[i] + ".attend == -2")
              ) {
                d.onlineSection3Attend++;
                d.onlineSection3Late++;
              }
            }
            for (let i = 0; i < section4.length; i++) {
              if (eval("d.onlineLesson" + section4[i] + ".attend == 1")) {
                d.onlineSection4Attend++;
              } else if (
                eval("d.onlineLesson" + section4[i] + ".attend == -1")
              ) {
                d.onlineSection4Attend++;
                d.onlineSection4Late++;
              } else if (
                eval("d.onlineLesson" + section4[i] + ".attend == -2")
              ) {
                d.onlineSection4Attend++;
                d.onlineSection4Late++;
              }
            }

            for (let i = 1; i < offlineNumber.value + 1; i++) {
              if (eval("d.offlineLesson" + i + ".attend == 1")) {
                d.offlineSection1Attend++;
              } else if (
                eval("d.offlineLesson" + i + ".attend == -1") ||
                eval("d.offlineLesson" + i + ".attend == -2")
              ) {
                d.offlineSection1Attend++;
                d.offlineSection1Late++;
              }
            }
            for (let i = 1; i < talkNumber.value + 1; i++) {
              if (eval("d.talkLesson" + i + ".attend == 1")) {
                d.talkSection1Attend++;
              } else if (
                eval("d.talkLesson" + i + ".attend == -1") ||
                eval("d.talkLesson" + i + ".attend == -2")
              ) {
                d.talkSection1Attend++;
                d.talkSection1Late++;
              }
            }

            onlineSection1Attend.value = d.onlineSection1Attend;
            onlineSection1Late.value = d.onlineSection1Late;
            onlineSection2Attend.value = d.onlineSection2Attend;
            onlineSection2Late.value = d.onlineSection2Late;
            onlineSection3Attend.value = d.onlineSection3Attend;
            onlineSection3Late.value = d.onlineSection3Late;
            onlineSection4Attend.value = d.onlineSection4Attend;
            onlineSection4Late.value = d.onlineSection4Late;
            offlineSection1Attend.value = d.offlineSection1Attend;
            offlineSection1Late.value = d.offlineSection1Late;
            talkSection1Attend.value = d.talkSection1Attend;
            talkSection1Late.value = d.talkSection1Late;
            onlineLessonAttend.value = d.onlineSection1Attend;
            onlineMeetAttend.value = d.onlineSection1Late;
            sum1.value =
              d.onlineSection1Attend +
              d.onlineSection2Attend +
              d.onlineSection3Attend +
              d.onlineSection4Attend +
              d.talkSection1Attend +
              d.offlineSection1Attend;
            sum2.value =
              d.onlineSection1Late +
              d.onlineSection2Late +
              d.onlineSection3Late +
              d.onlineSection4Late +
              d.talkSection1Late +
              d.offlineSection1Late;
          }
        }

        //졸업일 기준 2일 전 0시 1분 ~ 7일 후 0시 1분 까지 졸업장 & 상장 다운로드 버튼 보여주기
        const searchQuery = "졸업식"; //학사일정에서 졸업(식) 일정 가져오기
        const url = `service/class2/course?name=${searchQuery}&grade=${token.grade}&semester=${token.semester}&year=${token.year}`;
        const responseCourse = await api.get(url);
        if (!api.isSuccess(responseCourse)) {
          return;
        }
        const graduation = responseCourse.data.data[0].lessonDate;
        // graduationDate 선언, 한국시간 기준
        const graduationDate = moment.tz(graduation, "UTC").tz("Asia/Seoul");

        // graduationDate에서 날짜 부분만 추출
        const graduationDateOnly = graduationDate.clone().startOf("day");

        // graduationDate에서 2일 전의 시간 설정
        const twoDaysBeforeGraduation = graduationDateOnly
          .clone()
          .subtract(2, "days")
          .startOf("day")
          .add(1, "minute");

        // graduationDate에서 7일 후의 시간 설정
        const sevenDaysAfterGraduation = graduationDateOnly
          .clone()
          .add(7, "days")
          .startOf("day")
          .add(1, "minute");

        // 현재 한국 시간 구하기
        const currentSeoulTime = moment.tz("Asia/Seoul");

        // 현재 날짜가 설정한 기간 사이에 있는지 확인
        if (
          userInfo.value.role.includes("master") ||
          userInfo.value.role.includes("manager") ||
          (currentSeoulTime >= twoDaysBeforeGraduation &&
            currentSeoulTime <= sevenDaysAfterGraduation)
        ) {
          isGraduationPeriod.value = true;
        } else {
          isGraduationPeriod.value = false;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getDataFromApi();

    const goDiploma = async (memberNo) => {
      try {
        const responseDiploma = await api.get(
          `service/class2/diploma/${memberNo}`
        );
        if (api.isSuccess(responseDiploma)) {
          setTimeout(function () {
            window.open(responseDiploma.data.msg, "_blank");
          }, 500);
        } else {
          console.error("API 요청 실패:", responseDiploma);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const goAward = async (memberNo) => {
      try {
        const responseDiploma = await api.get(
          `service/class2/diploma/${memberNo}?award=yes`
        );
        if (api.isSuccess(responseDiploma)) {
          setTimeout(function () {
            window.open(responseDiploma.data.msg, "_blank");
          }, 500);
        } else {
          console.error("API 요청 실패:", responseDiploma);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    return {
      perPage, // 페이지당 레코드 수
      question2,
      tableData,
      tableToggle,
      show,
      onlineSection1Attend,
      onlineSection1Late,
      onlineSection2Attend,
      onlineSection2Late,
      onlineSection3Attend,
      onlineSection3Late,
      onlineSection4Attend,
      onlineSection4Late,
      offlineSection1Attend,
      offlineSection1Late,
      talkSection1Attend,
      talkSection1Late,
      onlineLessonAttend,
      onlineMeetAttend,
      sum1,
      sum2,
      sum0,
      diploma,
      award,
      memberNo,
      buldae,
      kyoungdae,
      goAward,
      goDiploma,
      isGraduationPeriod,
      totalNumber,
      section1Name,
      section2Name,
      section3Name,
      section4Name,
      section1Number,
      section2Number,
      section3Number,
      section4Number,
      talkNumber,
      offlineNumber,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.parent {
  display: flex;
  padding: 6px;
}
.parent .child {
  flex: 1;
  text-align: center;
}
p {
  font-weight: bold;
}
</style>
