<!-- 법문 다시 듣기 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">
                    법문 듣기 [법문은 안정된 장소에서 집중해서 듣습니다]
                  </h3>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div v-if="isMessage">
                <font size="5" color="blue">{{ message }}</font>
              </div>
              <div v-else>
                <table align="center" width="100%">
                  <td class="list-group-item px-2">
                    <p class="text-left" v-if="grade == '불교대학'">
                      <font color="blue">주제질문</font>
                    </p>
                    <p class="text-left" v-else>
                      <font color="blue">법문중에서</font>
                    </p>
                    <hr />
                    <p class="text-left" v-html="subjectQuestion"></p>
                    <hr />
                    <p class="text-left">
                      {{ start1 }}월 {{ start2 }}일 ~ {{ end1 }}월 {{ end2 }}일
                    </p>
                    <hr />
                    <h4 class="text-left">{{ lessonName1 }}</h4>
                  </td>
                </table>
                <table align="center" width="100%">
                  <tr>
                    <td width="50%" style="border: solid 0.1pt silver">
                      <p class="text-left ml-2">{{ name1 }}</p>
                      <br />

                      <div class="ml-4 mt-2">
                        <base-button
                          outline
                          type="warning"
                          icon
                          @click="goLesson1(1)"
                          ><span class="btn-inner--icon"
                            ><i class="ni ni-chat-round text-orange"></i
                          ></span>
                          <span class="btn-inner--text">수업듣기1</span>
                        </base-button>
                        <br /><br />
                      </div>
                    </td>

                    <td width="50%" style="border: solid 0.1pt silver">
                      <p class="text-left ml-2">{{ name2 }}</p>

                      <br />
                      <div class="ml-4 mt-2">
                        <base-button
                          outline
                          type="warning"
                          icon
                          @click="goLesson2(1)"
                          v-if="name2"
                          ><span class="btn-inner--icon"
                            ><i class="ni ni-chat-round text-orange"></i
                          ></span>
                          <span class="btn-inner--text">수업듣기2</span>
                        </base-button>
                        <br /><br />
                      </div>
                    </td>
                  </tr>
                </table>
                <table align="center" width="100%">
                  <td class="list-group-item px-2" v-if="lessonName2">
                    <p class="text-left" v-if="grade == '불교대학'">
                      <font color="blue">주제질문</font>
                    </p>
                    <p class="text-left" v-else>
                      <font color="blue">법문중에서</font>
                    </p>
                    <p class="text-left" v-html="subjectQuestion2"></p>
                    <hr />
                    <p class="text-left">
                      {{ start3 }}월 {{ start4 }}일 ~ {{ end3 }}월 {{ end4 }}일
                    </p>
                    <hr />
                    <h4 class="text-left">{{ lessonName2 }}</h4>
                  </td>
                </table>
                <table align="center" width="100%">
                  <tr>
                    <td
                      width="50%"
                      style="border: solid 0.1pt silver"
                      v-if="name3"
                    >
                      <p class="text-left ml-2">{{ name3 }}</p>
                      <br />

                      <div class="ml-6 mt-2">
                        <base-button
                          outline
                          type="warning"
                          icon
                          @click="goLesson1(2)"
                          ><span class="btn-inner--icon"
                            ><i class="ni ni-chat-round text-orange"></i
                          ></span>
                          <span class="btn-inner--text">수업듣기3</span>
                        </base-button>
                        <br /><br />
                      </div>
                    </td>

                    <td
                      width="50%"
                      style="border: solid 0.1pt silver"
                      v-if="name3"
                    >
                      <p class="text-left ml-2">{{ name4 }}</p>

                      <br />
                      <div class="ml-6 mt-2">
                        <base-button
                          outline
                          type="warning"
                          icon
                          @click="goLesson2(2)"
                          v-if="name4"
                          ><span class="btn-inner--icon"
                            ><i class="ni ni-chat-round text-orange"></i
                          ></span>
                          <span class="btn-inner--text">수업듣기4</span>
                        </base-button>
                        <br /><br />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <div
                        class="card-body text-center embed-youtube"
                        v-if="isUrl1"
                      >
                        <iframe
                          id="player"
                          type="text/html"
                          :src="url1"
                          allowfullscreen
                          frameborder="0"
                        ></iframe>
                      </div>
                      <div
                        class="card-body text-center embed-youtube"
                        v-if="isUrl2"
                      >
                        <iframe
                          id="player"
                          type="text/html"
                          :src="url2"
                          allowfullscreen
                          frameborder="0"
                        ></iframe>
                      </div>
                      <div
                        class="card-body text-center embed-youtube"
                        v-if="isUrl3"
                      >
                        <iframe
                          id="player"
                          type="text/html"
                          :src="url3"
                          allowfullscreen
                          frameborder="0"
                        ></iframe>
                      </div>
                      <div
                        class="card-body text-center embed-youtube"
                        v-if="isUrl4"
                      >
                        <iframe
                          id="player"
                          type="text/html"
                          :src="url4"
                          allowfullscreen
                          frameborder="0"
                        ></iframe>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment-timezone";
import { Auth } from "@/api";
import { ref } from "vue";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
  },
  // 데이터 객체 추가
  data() {
    const tableData = ref([]); // 레코드셋
    const tableData2 = ref([]); // 레코드셋
    const perPage = ref(10); // 페이지당 레코드 수
    const total = ref(0); // 총 레코드 수
    const message = ref("");
    const isMessage = ref(false);
    const subjectQuestion = ref("");
    const subjectQuestion2 = ref("");
    const start1 = ref(0);
    const start2 = ref(0);
    const start3 = ref(0);
    const start4 = ref(0);
    const lessonName1 = ref("");
    const lessonName2 = ref("");
    const name1 = ref("");
    const name2 = ref("");
    const name3 = ref("");
    const name4 = ref("");
    const url1 = ref("");
    const url2 = ref("");
    const url3 = ref("");
    const url4 = ref("");
    const end1 = ref(0);
    const end2 = ref(0);
    const end3 = ref(0);
    const end4 = ref(0);
    const isUrl1 = ref(false);
    const isUrl2 = ref(false);
    const isUrl3 = ref(false);
    const isUrl4 = ref(false);
    const lessonNo = ref(1);
    const lessonNo2 = ref(1);
    const isStudent = ref(false);
    const isTwo = ref(false);
    const grade = ref("");
    let endLessonTime1 = null;
    let endLessonTime2 = null;
    let lessonDate1 = null;
    let lessonDate2 = null;

    // api 호출
    const token = Auth.tokenContent;

    // 첫 수업의 법문 영상 시작 시간 확인
    const getOtDate = async () => {
      moment.tz.setDefault("Asia/Seoul");
      moment.locale("ko");
      const url = `service/class2/course?grade=${token.grade}&semester=${token.semester}&year=${token.year}`;
      try {
        const response = await api.get(url);
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        const [firstLesson] = d.filter((row) => row.lessonNo === 2);
        return firstLesson.start2;
      } catch (error) {
        console.error(error);
        return;
      }
    };

    const getDataFromApi = (page = 1) => {
      //하드코드
      //token.role = "student";
      if (token.role == "student") {
        isStudent.value = true;
      }
      grade.value = token.grade;
      const url = `service/class2/lesson?page=${page}&grade=${token.grade}&semester=${token.semester}&year=${token.year}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          isMessage.value = false;
          endLessonTime1 = d[0]?.end; // 법문듣기 종료일 of 오늘 이후 강의
          endLessonTime2 = d[1]?.end; // 법문듣기 종료일 of 오늘 이전 강의
          lessonDate1 = d[0]?.lessonDate; // 수업일 of 오늘 이후 강의
          lessonDate2 = d[1]?.lessonDate; // 수업일 of 오늘 이전 강의
          start1.value = moment(d[0].start).format("M");
          start2.value = moment(d[0].start).format("D");
          end1.value = moment(d[0].end).format("M");
          end2.value = moment(d[0].end).format("D");
          lessonName1.value = d[0].name;
          lessonNo.value = d[0].lessonNo;
          if (d[0].subjectQuestion) {
            subjectQuestion.value = d[0].subjectQuestion;
            // 주제질문 내용 중 http로 시작하는 링크 주소마다 a tag를 추가, 클릭하여 새창에 띄워줌
            let regex = /(https?:\/\/[^\s]+)/g;
            subjectQuestion.value = subjectQuestion.value
              .replace(regex, '<a href="$1" target="_blank">$1</a>')
              .replace(/\n/g, "<br>");
          }
          let i = 1;
          for (const row of d[0].lecture) {
            let isLive = row.url.includes("live");
            let isYoutube = row.url.includes("watch");
            let isYoutu = row.url.includes("youtu");
            let lessonUrlCodeArray = "";
            let lessonUrlCode = "";
            if (i == 1) {
              if (isLive) {
                name1.value = row.name;
                lessonUrlCodeArray = row.url.split("live/");
                lessonUrlCode = lessonUrlCodeArray[1];
                url1.value =
                  `https://www.youtube.com/embed/${lessonUrlCode}` +
                  "?rel=0&autoplay=0&modestbranding=1";
              } else if (isYoutube) {
                name1.value = row.name;
                lessonUrlCodeArray = row.url.split("=");
                lessonUrlCode = lessonUrlCodeArray[1];
                url1.value =
                  `https://www.youtube.com/embed/${lessonUrlCode}` +
                  "?rel=0&autoplay=0&modestbranding=1";
              } else if (isYoutu) {
                name1.value = row.name;
                lessonUrlCodeArray = row.url.split("/");
                lessonUrlCode = lessonUrlCodeArray[3];
                url1.value =
                  `https://www.youtube.com/embed/${lessonUrlCode}` +
                  "?rel=0&autoplay=0&modestbranding=1";
              }
            } else if (i == 2) {
              if (isLive) {
                name1.value = row.name;
                lessonUrlCodeArray = row.url.split("live/");
                lessonUrlCode = lessonUrlCodeArray[1];
                url1.value =
                  `https://www.youtube.com/embed/${lessonUrlCode}` +
                  "?rel=0&autoplay=0&modestbranding=1";
              } else if (isYoutube) {
                name2.value = row.name;
                lessonUrlCodeArray = row.url.split("=");
                lessonUrlCode = lessonUrlCodeArray[1];
                url2.value =
                  `https://www.youtube.com/embed/${lessonUrlCode}` +
                  "?rel=0&autoplay=0&modestbranding=1";
              } else if (isYoutu) {
                name2.value = row.name;
                lessonUrlCodeArray = row.url.split("/");
                lessonUrlCode = lessonUrlCodeArray[3];
                url2.value =
                  `https://www.youtube.com/embed/${lessonUrlCode}` +
                  "?rel=0&autoplay=0&modestbranding=1";
              }
            }
            i++;
          }
          if (data.rows > 1) {
            isTwo.value = true;
            if (d[1].subjectQuestion) {
              subjectQuestion2.value = d[1].subjectQuestion;
              // 주제질문 내용 중 http로 시작하는 링크 주소마다 a tag를 추가, 클릭하여 새창에 띄워줌
              let regex = /(https?:\/\/[^\s]+)/g;
              subjectQuestion2.value = subjectQuestion2.value
                .replace(regex, '<a href="$1" target="_blank">$1</a>')
                .replace(/\n/g, "<br>");
            }
            start3.value = moment(d[1].start).format("M");
            start4.value = moment(d[1].start).format("D");
            end3.value = moment(d[1].end).format("M");
            end4.value = moment(d[1].end).format("D");
            lessonName2.value = d[1].name;
            lessonNo2.value = d[1].lessonNo;
            let j = 1;
            for (const row2 of d[1].lecture) {
              let isYoutube = row2.url.includes("youtube");
              let isYoutu = row2.url.includes("youtu");
              let lessonUrlCodeArray = "";
              let lessonUrlCode = "";
              if (j == 1) {
                name3.value = row2.name;
                // this.isUrl3 = true;
                if (isYoutube) {
                  lessonUrlCodeArray = row2.url.split("=");
                  lessonUrlCode = lessonUrlCodeArray[1];
                  url3.value =
                    `https://www.youtube.com/embed/${lessonUrlCode}` +
                    "?rel=0&autoplay=0&modestbranding=1";
                } else if (isYoutu) {
                  lessonUrlCodeArray = row2.url.split("/");
                  lessonUrlCode = lessonUrlCodeArray[3];
                  url3.value =
                    `https://www.youtube.com/embed/${lessonUrlCode}` +
                    "?rel=0&autoplay=0&modestbranding=1";
                }
              } else if (j == 2) {
                name4.value = row2.name;
                // this.isUrl4 = true;
                if (isYoutube) {
                  lessonUrlCodeArray = row2.url.split("=");
                  lessonUrlCode = lessonUrlCodeArray[1];
                  url4.value =
                    `https://www.youtube.com/embed/${lessonUrlCode}` +
                    "?rel=0&autoplay=0&modestbranding=1";
                } else if (isYoutu) {
                  lessonUrlCodeArray = row2.url.split("/");
                  lessonUrlCode = lessonUrlCodeArray[3];
                  url4.value =
                    `https://www.youtube.com/embed/${lessonUrlCode}` +
                    "?rel=0&autoplay=0&modestbranding=1";
                }
              }
              j++;
            }
          }
        } else {
          moment.tz.setDefault("Asia/Seoul");
          let now = moment();

          // 첫 수업 법문영상 시작 시간 안내 문구
          getOtDate().then((firstLessonStart) => {
            // now = moment("2024-09-26 21:10")
            if (now < moment(firstLessonStart)) {
              message.value = `법문은 ${moment(firstLessonStart).format(
                "M월 D일 A h시"
              )}에 업로드 됩니다.`;
            } else {
              message.value = `법문은 ${moment(firstLessonStart).format(
                "dddd A h시"
              )}에 업로드 됩니다.`;
            }
          });
          isMessage.value = true;
        }
      });
    };
    getDataFromApi();
    const goMeet = (url) => {
      window.open(url);
    };
    const goLesson1 = (number) => {
      // 수업 시작 시간 이후 법문 클릭 시 법문듣기 체크 안되게 설정
      // 영상 클릭 시간(now)이 startTime ~ finishTime 이면 출석 체크 스킵 (주간 09:59:59~, 저녁 19:59:59~)

      let now = moment(); // 현재 시간
      // let now = moment("2024-04-02 09:00:00"); // 현재 시간 수기 설정

      let finishTime = null;
      let classDay = null;
      if (number === 1) {
        finishTime = moment(endLessonTime1);
        classDay = moment(lessonDate1); // 수업일 (법문)
      } else {
        finishTime = moment(endLessonTime2);
        classDay = moment(lessonDate2); // 수업일 (법문)
      }
      let morningStartTime = classDay
        .clone()
        .set({ hour: 9, minute: 59, second: 59 }); // 오전반 수업 시작 시간
      let afternoonStartTime = classDay
        .clone()
        .set({ hour: 19, minute: 59, second: 59 }); // 저녁반 수업 시작 시간

      let noCheckZone = false;

      if (token.local !== "해외") {
        if (token.belong === "주간") {
          if (now >= morningStartTime && now <= finishTime) {
            noCheckZone = true;
          }
        } else {
          if (now >= afternoonStartTime && now <= finishTime) {
            noCheckZone = true;
          }
        }
      }
      console.log("현재:", now.format("YYYY-MM-DD HH:mm:ss"));
      console.log(
        "오전 시작 시간:",
        morningStartTime.format("YYYY-MM-DD HH:mm:ss")
      );
      console.log(
        "저녁 시작 시간:",
        afternoonStartTime.format("YYYY-MM-DD HH:mm:ss")
      );
      console.log(
        "법문 듣기 종료 시간:",
        finishTime.format("YYYY-MM-DD HH:mm:ss")
      );
      console.log("출첵스킵:", noCheckZone);

      let lessonNo3 = 0;
      if (number === 1) {
        lessonNo3 = lessonNo.value;
      } else if (number === 2) {
        lessonNo3 = lessonNo2.value;
      }

      if (isStudent.value && lessonNo3 != 1 && !noCheckZone) {
        api.put(`service/class2/lesson31/${lessonNo3}`, {}).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          if (data2.msg === "ok") {
            if (number == 1) {
              isUrl1.value = true;
              isUrl2.value = false;
              isUrl3.value = false;
              isUrl4.value = false;
            } else if (number == 2) {
              isUrl1.value = false;
              isUrl2.value = false;
              isUrl3.value = true;
              isUrl4.value = false;
            }
          }
        });
      } else {
        if (number == 1) {
          isUrl1.value = true;
          isUrl2.value = false;
          isUrl3.value = false;
          isUrl4.value = false;
        } else if (number == 2) {
          isUrl1.value = false;
          isUrl2.value = false;
          isUrl3.value = true;
          isUrl4.value = false;
        }
      }
    };
    const goLesson2 = (number) => {
      // 수업 시작 시간 이후 법문 클릭 시 법문듣기 체크 안되게 설정
      // 영상 클릭 시간(now)이 startTime ~ finishTime 이면 출석 체크 스킵 (주간 09:59:59~, 저녁 19:59:59~)

      let now = moment(); // 현재 시간
      // now = moment("2024-09-26 20:10:00"); // 현재 시간 수기 설정

      let finishTime = null;
      let classDay = null;
      if (number === 1) {
        finishTime = moment(endLessonTime1);
        classDay = moment(lessonDate1); // 수업일 (법문)
      } else {
        finishTime = moment(endLessonTime2);
        classDay = moment(lessonDate2); // 수업일 (법문)
      }
      let morningStartTime = classDay
        .clone()
        .set({ hour: 9, minute: 59, second: 59 }); // 오전반 수업 시작 시간
      let afternoonStartTime = classDay
        .clone()
        .set({ hour: 19, minute: 59, second: 59 }); // 저녁반 수업 시작 시간

      let noCheckZone = false;

      if (token.local !== "해외") {
        if (token.belong === "주간") {
          if (now >= morningStartTime && now <= finishTime) {
            noCheckZone = true;
          }
        } else {
          if (now >= afternoonStartTime && now <= finishTime) {
            noCheckZone = true;
          }
        }
      }
      console.log("현재:", now.format("YYYY-MM-DD HH:mm:ss"));
      console.log(
        "오전 시작 시간:",
        morningStartTime.format("YYYY-MM-DD HH:mm:ss")
      );
      console.log(
        "저녁 시작 시간:",
        afternoonStartTime.format("YYYY-MM-DD HH:mm:ss")
      );
      console.log(
        "법문 듣기 종료 시간:",
        finishTime.format("YYYY-MM-DD HH:mm:ss")
      );
      console.log("출첵스킵:", noCheckZone);

      let lessonNo3 = 0;
      if (number === 1) {
        lessonNo3 = lessonNo.value;
      } else if (number === 2) {
        lessonNo3 = lessonNo2.value;
      }

      if (isStudent.value && !noCheckZone) {
        api.put(`service/class2/lesson32/${lessonNo3}`, {}).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          if (data2.msg === "ok") {
            if (number == 1) {
              isUrl2.value = true;
              isUrl1.value = false;
              isUrl3.value = false;
              isUrl4.value = false;
            } else if (number == 2) {
              isUrl2.value = false;
              isUrl1.value = false;
              isUrl3.value = false;
              isUrl4.value = true;
            }
          }
        });
      } else {
        if (number == 1) {
          isUrl2.value = true;
          isUrl1.value = false;
          isUrl3.value = false;
          isUrl4.value = false;
        } else if (number == 2) {
          isUrl2.value = false;
          isUrl1.value = false;
          isUrl3.value = false;
          isUrl4.value = true;
        }
      }
    };
    return {
      tableData,
      tableData2,
      perPage,
      total,
      message,
      isMessage,
      subjectQuestion,
      subjectQuestion2,
      start1,
      start2,
      start3,
      start4,
      lessonName1,
      lessonName2,
      name1,
      name2,
      name3,
      name4,
      url1,
      url2,
      url3,
      url4,
      end1,
      end2,
      end3,
      end4,
      isUrl1,
      isUrl2,
      isUrl3,
      isUrl4,
      lessonNo,
      lessonNo2,
      isStudent,
      isTwo,
      grade,
      goMeet,
      goLesson1,
      goLesson2,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.embed-youtube {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.embed-youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
